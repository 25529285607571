import React, { useEffect, useMemo } from "react";

import { OrderBy, useDriveContext } from "@drive/context";

import { NoBookings } from "../../_lib/NoBookings";

import { Spinner } from "~/components/Spinner";

import Card from "@drive/components/Card";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";

import { Appointment } from "@api/graphql/types";

const useStyles = makeStyles(() => {
	return {
		content: {
			padding: 24,
		},
		noBookings: {
			padding: 24,
		},
		spinner: {
			width: 48,
			height: 48,
			alignSelf: "center",
		},
	};
});

export const Display: React.FC = () => {
	const { appointments, loading, error, filter, setFilter } =
		useDriveContext();
	const styles = useStyles();

	useEffect(() => {
		if (filter.orderBy !== OrderBy.NEWEST) {
			setFilter({ orderBy: OrderBy.NEWEST, limit: 200 });
		}
	}, [filter, setFilter]);

	const content = useMemo(() => {
		if (loading) {
			return <Spinner className={styles.spinner} />;
		}

		if (!appointments.length) {
			return (
				<div className={styles.noBookings}>
					<NoBookings />
				</div>
			);
		}

		return (
			<Box className={styles.content}>
				<Grid container spacing={2}>
					{appointments?.map(
						(appointment: Appointment, index: number) => {
							return (
								<Card key={index} appointment={appointment} />
							);
						},
					)}
				</Grid>
			</Box>
		);
	}, [loading, appointments, styles]);

	return (
		<Box
			display="flex"
			justifyContent={loading || error ? "center" : ""}
			flexDirection="column"
			flexWrap="nowrap"
			height="100%"
		>
			{content}
		</Box>
	);
};
